import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { compose } from "redux"
import { connect } from "react-redux"
import Page from "../components/Page"
import GetLatestPageData from "../helpers/GetLatestPageData"
import { GetPage } from "../actions/ContentfulActions"

const IndexPage = ({ getPage, data, contentful }) => {
  const { contentfulPage } = data
  const { pages } = contentful

  useEffect(() => {
    getPage(contentfulPage.contentful_id)
  }, [contentfulPage.contentful_id, getPage])

  const parsed = GetLatestPageData(contentfulPage, pages)

  return <Page zeroTop {...parsed} />
}

IndexPage.propTypes = {
  getPage: PropTypes.func.isRequired,
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      contentful_id: PropTypes.string.isRequired,
    }),
  }),
  contentful: PropTypes.object,
}

export default compose(
  connect(
    (state) => {
      return {
        contentful: state.contentful,
      }
    },
    (dispatch) => {
      return {
        getPage: (id) => dispatch(GetPage(id)),
      }
    }
  )
)(IndexPage)

export const pageQuery = graphql`
  query IndexPageQuery {
    contentfulPage(rallistaId: { eq: "index" }) {
      contentful_id
      seoTitle
      rallistaId
      seoDescription
      sections {
        ... on ContentfulFeatureSection {
          internal {
            type
          }
          title
          buttonLink
          buttonTitle
          body {
            raw
          }
          justifyImage
          media {
            title
            description
            contentful_id
            file {
              url
              fileName
              contentType
              details {
                size
              }
            }
          }
          contentful_id
        }
        ... on ContentfulHeroSection {
          internal {
            type
          }
          title
          background {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
          titleImage {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
          subTitle
          justifyActionBox
          actionBox {
            buttonLink
            buttonTitle
            body {
              raw
            }
            contentful_id
            title
          }
          contentful_id
        }
        ... on ContentfulTextSection {
          internal {
            type
          }
          id
          title
          richText {
            raw
          }
          contentful_id
        }
        ... on ContentfulDownloadSection {
          internal {
            type
          }
          id
          titleImage {
            file {
              contentType
              fileName
              url
              details {
                size
              }
            }
            contentful_id
            title
            description
          }
        }
      }
    }
  }
`
